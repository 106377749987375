<template>
  <div class="fixed left-0 top-0 z-10 m-2">
    <Button variant="link" @click="app.leftNavPinned = true">
      <IconMenu class="h-5 w-5" />
    </Button>
  </div>
  <div
    ref="el"
    class="fixed inset-0 z-20 hidden w-full max-w-[15em] flex-col bg-dark lg:relative lg:flex lg:border-r lg:border-white/10"
    :class="{
      '!flex !border-r !shadow': app.leftNavPinned,
    }"
    :style="{
      background: `var(--bg1)`,
    }"
  >
    <div class="w-full p-4">
      <UserNav class="w-full" />
    </div>

    <ul class="flex-grow p-4">
      <li
        class="whitespace-nowrap"
        :class="{ 'force-not-active': onAlertBoxRoute }"
      >
        <NuxtLink
          to="/widgets"
          @click="$track('left_nav_clicked', { nav_item_name: 'widgets' })"
        >
          <IconWidgets class="h-6 w-6 p-0.5" />
          <span>{{ $t("pages.title.widgets") }}</span>
        </NuxtLink>
      </li>
      <!--
      <li>
        <NuxtLink
          to="/overlays"
          @click="$track('left_nav_clicked', { nav_item_name: 'overlays' })"
        >
          <IconOverlays class="h-6 w-6 p-0.5" />
          <span>{{ $t("pages.title.overlays") }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          to="/widget-themes"
          @click="
            $track('left_nav_clicked', { nav_item_name: 'widget-themes' })
          "
        >
          <Icon size="1.5rem" name="fa6-solid:brush" />
          <span>{{ $t("pages.title.widget-themes") }}</span>
        </NuxtLink>
      </li>
      -->
      <li>
        <NuxtLink
          to="/library"
          @click="$track('left_nav_clicked', { nav_item_name: 'library' })"
        >
          <IconOverlays class="h-6 w-6 p-0.5" />
          <span>{{ $t("Overlays & Themes") }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          to="/stream-labels"
          @click="
            $track('left_nav_clicked', { nav_item_name: 'stream-labels' })
          "
        >
          <IconStreamLabels class="h-6 w-6 p-0.5" />
          <span>{{ $t("pages.title.stream-labels") }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          to="/cloudbot"
          @click="$track('left_nav_clicked', { nav_item_name: 'cloudbot' })"
        >
          <IconCloudbot class="h-6 w-6 p-0.5" />
          <span>{{ $t("pages.title.cloudbot") }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          to="/tipping"
          @click="$track('left_nav_clicked', { nav_item_name: 'tipping' })"
        >
          <IconTipping class="h-6 w-6 p-0.5" />
          <span>{{ $t("pages.title.tipping") }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          to="/multistream"
          @click="$track('left_nav_clicked', { nav_item_name: 'multistream' })"
        >
          <IconMultistream class="h-6 w-6 p-0.5" />
          <span>{{ $t("pages.title.multistream") }}</span>
        </NuxtLink>
      </li>
    </ul>

    <ul class="p-4">
      <Dialog
        v-if="auth.socketStatus === 'FAILED_TO_CONNECT'"
        :title="$t('Websocket Connection Error')"
        :description="
          $t(
            `We we're unable to connect to the Streamlabs Websocket. Please restart OBS and contact support if the issue persist.`,
          )
        "
      >
        <template #trigger>
          <li class="text-red-500">
            <DialogTrigger as-child>
              <NuxtLink>
                <Icon size="1.5em" name="lucide:unplug" />
                <span>{{ $t("Disconnected") }}</span>
              </NuxtLink>
            </DialogTrigger>
          </li>
        </template>
      </Dialog>

      <li v-if="auth.isAuthenticated">
        <MenusSimulateEvents
          @click="$track('left_nav_clicked', { nav_item_name: 'test_events' })"
        >
          <NuxtLink>
            <Icon size="1.5em" name="bxs:wrench" />
            <span>{{ $t("Test Events") }}</span>
          </NuxtLink>
        </MenusSimulateEvents>
      </li>
      <li v-if="!auth.isUltra">
        <NuxtLink
          :to="urlToUltra"
          @click.stop.prevent="
            () => {
              $track('left_nav_clicked', { nav_item_name: 'ultra' });
              openBrowserTo(urlToUltra);
            }
          "
        >
          <IconUltra class="h-6 w-6" />
          <span>{{ $t("pages.title.ultra") }}</span>
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          to="/settings"
          @click="$track('left_nav_clicked', { nav_item_name: 'settings' })"
        >
          <!-- <Icon size="1.5em" name="lucide:settings" /> -->
          <IconSettings class="h-6 w-6 p-0.5" />
          <span>{{ $t("pages.title.settings") }}</span>
        </NuxtLink>
      </li>

      <!--
      <li>
        <Button
          variant="link"
          class="!no-underline"
          @click="
            () => {
              dialogs.showDialog('FEEDBACK');
              $track('left_nav_clicked', { nav_item_name: 'feedback' });
            }
          "
        >
          <div class="space-x-2">
            <Icon name="bxs:message-dots" class="h-6 w-6" />
            <span>{{ $t("Send Feedback") }}</span>
          </div>
        </Button>
      </li>
      -->

      <li v-if="false" class="pb-2 pt-4">
        <Button
          v-if="!obs.isStreaming"
          class="w-full"
          variant="neutral"
          @click="onStartStream"
        >
          <IconRecord class="-ml-1.5 mr-1.5 h-5 w-5 opacity-70" />
          <span>{{ $t("Start Streaming") }}</span>
        </Button>
        <Button v-else class="w-full" variant="danger" @click="onStopStream">
          <IconStopRecording class="-ml-1.5 mr-1.5 h-5 w-5 opacity-70" />
          <span>{{ $t("Stop Streaming") }}</span>
        </Button>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { openBrowserTo } from "~/lib/bindings";
import { getUltraUrl } from "~/lib/ultra";
const { track: $track } = useTrackingStore();
const router = useRouter();

const el = ref(null);

onClickOutside(el, (event) => {
  app.leftNavPinned = false;
});

const route = useRoute();

const onAlertBoxRoute = computed(() => route.path === "/widgets/alert-box");

const auth = useAuthStore();
const app = useAppStore();
const obs = useObsStore();
const dialogs = useDialogsStore();

const onStartStream = () => {
  $track("left_nav_clicked", { nav_item_name: "start_streaming" });
  dialogs.showDialog("GO_LIVE");
};

const onStopStream = () => {
  $track("left_nav_clicked", { nav_item_name: "stop_streaming" });
  window.obsStreamButtonClicked?.();
};

const urlToUltra = getUltraUrl({ refl: "obsplugin-left-nav" }, false);

// unpin the menu when we navigate
router.beforeResolve((to, from) => {
  app.leftNavPinned = false;
});
</script>

<style scoped>
ul {
  @apply space-y-1;
}

li {
  @apply whitespace-nowrap;

  a,
  button {
    -webkit-user-drag: none;

    @apply inline-flex w-full items-center space-x-2 rounded-lg px-4 py-2 pr-12;

    svg {
      @apply flex-shrink-0 opacity-75 brightness-75;
    }
  }

  &:not(.force-not-active) a.router-link-active,
  &:hover a,
  &:hover button {
    @apply bg-outer-space-950 brightness-150;

    svg {
      @apply opacity-100 brightness-100;
    }
  }
}
</style>
