<template>
  <!-- <template v-if="app.preloading">
    <div class="fixed inset-0 flex items-center justify-center">
      <LoadingSpinner class="h-16 w-16" />
    </div>
  </template>
  <template v-else> -->
  <AppDialogs />

  <div class="flex h-full w-full flex-row">
    <LeftNav />
    <div class="flex-grow">
      <slot />
    </div>
  </div>

  <Button
    class="fixed bottom-0 right-0 z-50 m-4 opacity-70 hover:opacity-100"
    @click="dialogs.showDialog('FEEDBACK')"
  >
    <div class="space-x-1">
      <Icon name="bxs:message-dots" class="h-5 w-5" />
      <span>Feedback</span>
    </div>
  </Button>

  <!-- </template> -->
</template>

<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import {
  bearerToken,
  fetchDonationSettings,
  fetchUserInfo,
  fetchBetaAccessStatus,
} from "@/lib/streamlabs-api";
import { setAsyncInterval } from "~/lib/util";

const auth = useAuthStore();
const app = useAppStore();
const dialogs = useDialogsStore();
const { track: $track } = useTrackingStore();
const nuxtApp = useNuxtApp();

// preload the multistream store so that things feel snappier
const multistream = useMultistreamStore();

// preload the widgets store so overlays properly install
const widgets = useWidgetsStore();

const onAuthed = (
  authResponseParams: NonNullable<typeof auth.authResponseParams>,
) => {
  bearerToken.value = authResponseParams.oauth_token;

  return Promise.all([
    fetchBetaAccessStatus.refresh().then(() => {
      const { allowed } = fetchBetaAccessStatus.data.value;
      auth.isBetaTester = allowed;
    }),
    fetchUserInfo().then(({ data: userInfo }) => {
      auth.userInfo = userInfo.value ?? undefined;
    }),
    fetchDonationSettings().then(({ data: donationSettings }) => {
      auth.donationSettings = donationSettings.value ?? undefined;
    }),
  ]);
};

const onUnauthed = () => {
  bearerToken.value = undefined;
  auth.userInfo = undefined;
};

$track("plugin_start");

// ping every hour
const cancelPingInterval = setAsyncInterval(
  () => {
    $track("ping", {
      is_ultra: auth.isUltra,
    });
  },
  1000 * 60 * 60,
);

const updateAvailable = ref(false);

nuxtApp.hook("app:manifest:update", (...args) => {
  updateAvailable.value = true;
  console.log("update available!", { args });
});

watch(
  () => auth.authResponseParams,
  (authResponseParams) => {
    authResponseParams ? onAuthed(authResponseParams) : onUnauthed();
  },
  { immediate: true },
);

watch(
  () => app.versionInfo,
  (versionInfo) => {
    Sentry.setTags({
      branch: versionInfo?.branch,
      git_sha: versionInfo?.git_sha,
      rev: versionInfo?.rev,
    });
  },
  { immediate: true },
);

watch(
  [() => auth.uuid, () => auth.userInfo],
  ([uuid, userInfo]) => {
    Sentry.setUser({
      uuid,
      id: userInfo?.id,
      username: userInfo?.display_name,
      ultra: userInfo?.ultra,
    });
  },
  { immediate: true },
);

useHead({
  style: computed(() =>
    app.dynamicZoomEnabled
      ? [
          `:root {
            --global-zoom: ${window.devicePixelRatio === 1 ? "0.85" : "0.98"};
            --scrollbar-thumb-border-size: ${window.devicePixelRatio === 1 ? "5px" : "3px"};
          }
      `,
        ]
      : [],
  ),
});

if (!app.onboardingComplete) {
  // show the plugin on startup if onboarding not completed
  app.resetDimentions();
  app.bringToFront();
}

app.checkForUpdates();
</script>
